import React, { useState } from 'react';
import { showToast } from '../utils/toast';
import authFetch from '../utils/authFetch';
import { FiLock, FiEye, FiEyeOff } from 'react-icons/fi'; // Eye icons
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useLoginStore from '../store/LoginStore';
import PuffLoader from "react-spinners/PuffLoader";
const Login = () => {
    const navigate = useNavigate();
    const { globalEmail, globalPassword, setGlobalEmail, setGlobalPassword } = useLoginStore()
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const validateForm = () => {
        const validationErrors = {};
        if (!globalEmail) {
            validationErrors.email = 'Email is required';
        }
        if (!globalPassword) {
            validationErrors.password = 'Password is required';
        }
        return validationErrors;
    };

    const handleLoginOTP = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setIsSubmitting(true);
        try {
            let body = {
                email: globalEmail,
                password: globalPassword
            }
            const response = await authFetch(`/api/v1/user/loginOtpV2`, {
                method: 'POST',
                body: body,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response?.code === 200) {
                showToast(response.message, 'success');
                onLogin(response);
            }
        } catch (err) {
            console.log("handleLoginOTP", err);
        }
        finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    const onLogin = async () => {
        try {
            navigate('/otp', { state: { type: "login" } }); // Navigate to OTP page
        } catch (err) {
            console.log("onLogin", err);
        }
    };

    const handleEmailChange = (e) => {
        setGlobalEmail(e.target.value);

        // Clear error when user types in the email input
        if (errors.email) {
            setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
        }
    };

    const handlePasswordChange = (e) => {
        setGlobalPassword(e.target.value);

        // Clear error when user types in the password input
        if (errors.password) {
            setErrors((prevErrors) => ({ ...prevErrors, password: '' }));
        }
    };

    return (
        <div className="flex flex-col justify-center h-full min-w-[20rem]">
            {/* PuffLoader with overlay */}
            {isSubmitting && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60 z-50">
                    <PuffLoader color="#10B981" size={100} />
                </div>
            )}
            <div className={`flex-1 overflow-auto ${isSubmitting ? 'filter blur-md' : ''}`}> {/* Blur when submitting */}
                <div className="flex items-center justify-center mb-6">
                    <FiLock className="text-green-500 h-8 w-8" />
                    <h2 className="text-3xl font-bold text-left text-gray-800 ml-2">Login</h2>
                </div>
                <form onSubmit={handleLoginOTP} className="space-y-6 w-full">
                    <div className="w-full">
                        <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={globalEmail}
                            onChange={handleEmailChange}
                            className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold`}
                            placeholder="Enter your email"
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                    </div>

                    <div className="w-full relative">
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-700 mb-2">Password</label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={globalPassword}
                            onChange={handlePasswordChange}
                            className={`w-full p-3 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold`}
                            placeholder="Enter your password"
                        />
                        <div
                            className="absolute top-11 right-4 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <FiEyeOff className="text-xl" /> : <FiEye className="text-xl" />}
                        </div>
                        {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white rounded-lg py-3 font-bold text-lg hover:bg-green-600 transition duration-200"
                    >
                        Login
                    </button>
                </form>

                <div className="mt-4 text-center">
                    <Link
                        to={'/forget-password'}
                        className="text-sm text-blue-500 hover:underline"
                    >
                        Forgot your password?
                    </Link>
                </div>

                <div className="mt-6 text-center w-full">
                    <p className="text-sm text-gray-600">
                        Don't have an account?{' '}
                        <Link
                            to={'/signup'}
                            className="text-green-500 font-semibold hover:underline"
                        >
                            Signup
                        </Link>
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Login;
