import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import CurrencySelector from './CurrencySelector'; // Import the CurrencySelector component
import CoinSelector from './CoinSelector';
import '../styles/Form.css'; // Import your CSS for transitions
import authFetch from '../utils/authFetch';
import UseSellSelectorStore from '../store/SellSelector';
import useProfileStore from '../store/profileStore';
import { Link } from 'react-router-dom';
import { GiMoneyStack } from "react-icons/gi";
const Sell = () => {
    const { globalProfile } = useProfileStore();
    const { globalCurrency, globalCoin, globalNetwork, setGlobalCurrency, setGlobalCoin, setGlobalNetwork, globalYouSell, globalYouGet, setGlobalYouSell, setGlobalYouGet, globalQuoteData, setGlobalQuoteData } = UseSellSelectorStore();

    const [isExpanded, setIsExpanded] = useState(false);
    const [focusedInput, setFocusedInput] = useState('sell');
    const [isSelectorOpen, setIsSelectorOpen] = useState(false);
    const [isCoinSelectorOpen, setIsCoinSelectorOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [coins, setCoins] = useState([]);
    const [filteredCurrency, setFilteredCurrency] = useState([]);
    const [filteredCoin, setFilteredCoin] = useState([]);
    const sellInputRef = useRef(null);
    const debounceTimeout = 1000;
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const preventNegative = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }
    };

    const handleButtonClick = (e, amount) => {
        e.preventDefault();
        setGlobalYouSell(amount);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setGlobalYouGet(value);
        }
    };

    const handleYouSellInputChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setGlobalYouSell(value);
        }
    };

    const getAllCurrencies = async () => {
        try {
            const response = await authFetch('/api/v1/user/getAllCurrencies', { method: 'GET' });
            if (!response?.code === 200) return;
            const currencyData = response?.data;
            setCurrencies(currencyData);
            setGlobalCurrency(currencyData[0]);
        } catch (error) {
            console.error('Error fetching getAllCurrencies:', error);
        }
    };

    const getAllCoins = async () => {
        try {
            const response = await authFetch('/api/v1/user/getAllCoins', { method: 'GET' });
            if (!response?.code === 200) return;
            const coinData = response?.data;
            setCoins(coinData);
            setGlobalCoin(coinData[0]);
            if (!globalNetwork) getAllNetworks(coinData[0]);
        } catch (error) {
            console.error('Error fetching getAllCoins:', error);
        }
    };

    const getAllNetworks = async (coinData) => {
        try {
            const response = await authFetch(`/api/v1/user/getAllNetworks?id=${coinData?.coinid}`, { method: 'GET' });
            if (!response?.code === 200) return;
            const networkData = response?.data;
            const defaultNetwork = networkData.find(n => n.chainSymbol === "trc20")
            setGlobalNetwork(defaultNetwork);
        } catch (error) {
            console.error('Error fetching getAllNetworks:', error);
        }
    };

    const getQuote = async () => {
        try {
            let body = {
                fromCurrency: globalCoin.coin.toUpperCase(),
                toCurrency: globalCurrency.fiatSymbol,
                fromAmount: globalYouSell,
                chain: globalNetwork.chainSymbol,
            };
            const response = await authFetch(`/api/v1/offramp/getQuotes`, {
                method: 'POST',
                body: body,
                headers: { 'Content-Type': 'application/json' },
            });
            if (!response?.code === 200) return;
            const quoteData = response?.data;
            setGlobalQuoteData(quoteData);
            setGlobalYouGet(quoteData?.toAmount);
        } catch (error) {
            console.error('Error fetching getQuote:', error);
        }
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (globalYouSell !== '' && globalYouSell >= Number(globalCoin?.minSellValue)) {
                getQuote();
            } else {
                setGlobalQuoteData(null);
                setGlobalYouGet('');
            }
        }, debounceTimeout);

        // Cleanup function to clear timeout if effect dependencies change
        return () => clearTimeout(debounceTimer);

    }, [globalYouSell, globalNetwork, globalCurrency]);

    useEffect(() => {
        sellInputRef.current.focus();
        const fetchInitialData = async () => {
            await getAllCurrencies();
            await getAllCoins();
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        let filteredFiat = currencies.filter(currency =>
            currency?.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
            currency?.fiatSymbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCurrency(filteredFiat);

        let filteredCrypto = coins.filter(coin =>
            coin.coinName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coin.coin.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCoin(filteredCrypto);
    }, [searchTerm, currencies, coins]);

    return (
        <div className="flex flex-col h-[570px]">
            <form className="space-y-6 text-sm flex-grow overflow-auto mt-4 px-4">
                {/* Sell Section */}
                <div className="space-y-3">
                    <label className="block text-black font-semibold">Sell</label>
                    <div
                        className={`flex items-center border-2 ${focusedInput === 'sell' ? 'border-green-500' : 'border-gray-300'} rounded-lg p-0.5 bg-white`}
                    >
                        <input
                            ref={sellInputRef}
                            type="number"
                            placeholder=""
                            className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none min-w-0"
                            onFocus={() => setFocusedInput('sell')}
                            onBlur={() => setFocusedInput('')}
                            onInput={preventNegative}
                            value={globalYouSell}
                            onChange={handleYouSellInputChange}
                        />
                        <div
                            onClick={() => setIsCoinSelectorOpen(true)}
                            className="flex items-center bg-gray-100 px-3 py-3 rounded-r-lg flex-shrink-0 max-w-[35%] cursor-pointer"
                        >
                            <img src={globalCoin?.coinIcon} alt="Coin Icon" className="h-5 w-5 rounded-full object-cover" />
                            <div className="flex flex-col items-start ml-1 min-w-0">
                                <div className="flex flex-row items-center min-w-0">
                                    <span className="text-black text-xs font-semibold uppercase truncate leading-none">{globalCoin?.coin}</span>
                                    <FaChevronDown className="ml-1 text-gray-500 flex-shrink-0" />
                                </div>
                                <span className="text-gray-500 uppercase text-[0.6rem] leading-none mt-1 truncate">{globalNetwork?.chainSymbol?.toUpperCase()}</span>
                            </div>
                        </div>
                    </div>
                    {globalYouSell && globalYouSell < Number(globalCoin?.minSellValue) && (
                        <p className="text-red-500 text-sm font-semibold">Min amount is {globalCoin?.coin?.toUpperCase()} {globalCoin?.minSellValue}</p>
                    )}
                    <div className="flex space-x-2">
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 1000)}
                        >
                            $1,000
                        </button>
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 5000)}
                        >
                            $5,000
                        </button>
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 10000)}
                        >
                            $10,000
                        </button>
                    </div>
                </div>

                {/* You Get Section */}
                <div className="space-y-2">
                    <label className="block text-black font-semibold">You get</label>
                    <div
                        className={`flex items-center border-2 ${focusedInput === 'get'
                            ? globalYouGet && globalYouGet < Number(globalCurrency?.minSellValue)
                                ? 'border-red-500'
                                : 'border-green-500'
                            : 'border-gray-300'
                            } rounded-lg p-0.5 bg-white`}
                    >
                        <input
                            type="number"
                            value={globalYouGet}
                            placeholder={globalCurrency?.currencySymbol && globalCurrency?.minSellValue ? `min ${globalCurrency?.currencySymbol}${globalCurrency?.minSellValue}` : ''}
                            className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none min-w-0"
                            onFocus={() => setFocusedInput('get')}
                            onBlur={() => setFocusedInput('')}
                            onInput={preventNegative}
                            onChange={handleInputChange}
                        />
                        <div
                            onClick={() => setIsSelectorOpen(true)}
                            className="flex items-center bg-gray-100 px-3 py-4 rounded-r-lg flex-shrink-0 max-w-[35%] cursor-pointer"
                        >
                            <img src={globalCurrency?.flagUrl} alt="Currency Flag" className="h-5 w-5 rounded-full object-cover" />
                            <span className="ml-2 text-black text-xs font-semibold uppercase truncate">{globalCurrency?.fiatSymbol}</span>
                            <FaChevronDown className="ml-3 text-gray-500 flex-shrink-0" />
                        </div>
                    </div>
                    {globalYouGet && globalYouGet < Number(globalCurrency?.minSellValue) && (
                        <p className="text-red-500 text-sm font-semibold">Min amount is {globalCurrency?.currencySymbol}{globalCurrency?.minSellValue}</p>
                    )}
                </div>

                {/* Conversion Rate */}
                {/* <div className="text-xs text-gray-500 flex justify-between -mt-1">
                    <span>1 {globalCoin && globalCoin?.coin?.toUpperCase()} ≈ {globalQuoteData && `₹${globalQuoteData?.rate}`}</span>
                </div> */}

                {/* You Pay Section */}
                <div className="border border-gray-300 bg-white rounded-lg ">
                    <div className="pl-4 flex justify-between items-center cursor-pointer" onClick={toggleExpand}>

                        <div className="flex-1 overflow-hidden">
                            <span className="text-black font-bold truncate block max-w-full">
                                1 {globalCoin && globalCoin?.coin?.toUpperCase()} ≈ {globalQuoteData && `₹${globalQuoteData?.rate}`}
                            </span>
                        </div>

                        <div
                            className="flex items-center bg-white px-3 py-4 rounded-r-lg flex-shrink-0 max-w-[35%] gap-1"
                        >
                            <GiMoneyStack className="h-5 w-5 rounded-full object-cover text-green-700" />
                            {/* <img src={globalCurrency?.flagUrl} alt="Currency Flag" className="h-5 w-5 rounded-full object-cover" /> */}
                            <span className="ml-2 text-black text-xs font-semibold uppercase truncate">Fees</span>
                            {isExpanded ? <FaChevronUp className="text-gray-500" /> : <FaChevronDown className="text-gray-500" />}
                        </div>

                    </div>

                    <CSSTransition in={isExpanded} timeout={300} classNames="expand" unmountOnExit>
                        <div className="px-4 pb-4 space-y-2">
                            <div className="flex justify-between pb-2">
                                <span>Network fee <span className="ml-1 text-gray-400">(0 USDT)</span></span>
                                <span>₹0</span>
                            </div>
                            <div className="flex justify-between pb-2">
                                <span className="flex items-center">Platform Fee</span>
                                <span className="truncate max-w-[100px]">{globalCurrency?.currencySymbol}{globalQuoteData ? globalQuoteData?.fees[0]?.onrampFee : 0}</span>
                            </div>
                            {globalQuoteData && (
                                <div className="flex justify-between pb-2">
                                    <span className="flex items-center">TDS (1%)</span>
                                    <span className="truncate max-w-[100px]">{globalCurrency?.currencySymbol}{globalQuoteData ? globalQuoteData?.fees[0]?.tdsFee : 0}</span>
                                </div>
                            )}
                            <div className="flex justify-between pb-2">
                                <span>Total fee</span>
                                <span className="truncate max-w-[100px]">{globalCurrency?.currencySymbol} {globalQuoteData ? (parseFloat(globalQuoteData?.fees[0]?.onrampFee) + parseFloat(globalQuoteData?.fees[0]?.tdsFee)).toFixed(2) : 0}</span>
                            </div>
                            {!globalQuoteData && (
                                <span className="text-xs text-gray-400">Note: TDS of 1% is levied by Income Tax department, GoI.</span>
                            )}
                        </div>
                    </CSSTransition>
                </div>
            </form>

            {/* Proceed Button */}
            <div className="p-4 bg-white">
                <Link
                    to={
                        globalYouSell < Number(globalCoin?.minSellValue) || !globalQuoteData || globalYouGet === ""
                            ? "#"
                            : !globalProfile
                                ? "/login"
                                : !globalProfile?.isPhoneAdded
                                    ? "/addPhone"
                                    : '/fiataccount'
                        // globalProfile?.isKycCompleted
                        //     ? '/fiataccount'
                        //     : "/before-kyc"
                    }
                    className={`w-full rounded-lg py-4 font-bold text-lg flex items-center justify-center space-x-2 h-14
                    ${globalYouSell < Number(globalCoin?.minSellValue) || !globalQuoteData || globalYouGet === ""
                            ? 'bg-green-200 text-white'
                            : 'bg-green-500 text-white hover:bg-green-600'}`}
                >
                    <span>Proceed</span>
                </Link>
            </div>

            {/* Currency Selector */}
            <CurrencySelector
                isOpen={isSelectorOpen}
                onClose={() => { setIsSelectorOpen(false); setSearchTerm('') }}
                currencies={filteredCurrency}
                onSelect={() => setIsSelectorOpen(false)}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
            />

            {/* Coin Selector */}
            <CoinSelector
                isOpen={isCoinSelectorOpen}
                onClose={() => { setIsCoinSelectorOpen(false); setSearchTerm('') }}
                coins={filteredCoin}
                onSelect={() => setIsCoinSelectorOpen(false)}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
            />
        </div>
    );
};

export default Sell;
