import { create } from 'zustand';
const useLoginStore = create((set) => ({
    globalEmail: '',      // Initial profile state
    globalPassword: '',
    setGlobalEmail: (data) => set({ globalEmail: data }), // Function to set Email 
    setGlobalPassword: (data) => set({ globalPassword: data }), // Function to set New Password 
    clearGlobalEmail: () => set({ globalEmail: '' }),  // Function to clear Email
    clearGlobalPassword: () => set({ globalPassword: '' }),  // Function to clear New Password
}));

export default useLoginStore;