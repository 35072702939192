import { create } from 'zustand';
const usePhoneStore = create((set) => ({
    globalPhone: '',      // Initial phone state
    globalSelectedCountry: {}, // Initial selected country state
    setGlobalPhone: (data) => set({ globalPhone: data }), // Function to set Phone 
    clearGlobalPhone: () => set({ globalPhone: '' }),  // Function to clear Phone
    setGlobalSelectedCountry: (data) => set({ globalSelectedCountry: data }), // Function to set selected country
    clearGlobalSelectedCountry: () => set({ globalSelectedCountry: {} }), // Function to clear selected country
}));

export default usePhoneStore;